import React from "react";
import "./footer.css";
import { BsLinkedin } from "react-icons/bs";
import { BsGithub } from "react-icons/bs";
import { BsMedium } from "react-icons/bs";

const Footer = () => {
  return (
    <div>
      <footer>
        <a href="#home" className="footer__logo">
          Avneet
        </a>
        <ul className="permalinks">
          <li>
            <a href="#home">Home</a>
          </li>
          <li>
            <a href="#about">About</a>
          </li>
          <li>
            <a href="#experience">Experience</a>
          </li>
          <li>
            <a href="#portfolio">Portfolio</a>
          </li>
          <li>
            <a href="#contact">Contact</a>
          </li>
        </ul>
        <div className="footer__socials">
          <a
            href="https://www.linkedin.com/in/the-avneet-singh/"
            target="_blank"
            rel="noreferrer"
          >
            <BsLinkedin />
          </a>
          <a
            href="https://github.com/avneetsingh36"
            target="_blank"
            rel="noreferrer"
          >
            <BsGithub />
          </a>
          <a
            href="https://medium.com/@avneet-singh"
            target="_blank"
            rel="noreferrer"
          >
            <BsMedium />
          </a>
        </div>

        <div className="footer__copyright">
          <small>&copy; Avneet Singh. All Rights Reserved.</small>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
