import React from "react";
import "./experience.css";
import { frontendSkills } from "./data.js";
import { backendSkills } from "./data.js";

const Experience = () => {
  return (
    <section id="experience">
      <h5>What Skills I Have</h5>
      <h2>My Experience</h2>

      <div className="container experience__container">
        <div className="experience__frontend">
          <h3>Frontend Development</h3>
          <div className="experience__content">
            {frontendSkills.map((skill) => (
              <article className="experience__data" key={skill.skill}>
                {skill.logo}
                <div>
                  <h4>{skill.skill}</h4>
                  <small className="text-light">{skill.proficiency}</small>
                </div>
              </article>
            ))}
          </div>
        </div>
        <div className="experience__backend">
          <h3>Backend Development</h3>
          <div className="experience__content">
            {backendSkills.map((skill) => (
              <article className="experience__data" key={skill.skill}>
                {skill.logo}
                <div>
                  <h4>{skill.skill}</h4>
                  <small className="text-light">{skill.proficiency}</small>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
