import React from "react";
import "./nav.css";

import { IoHomeOutline } from "react-icons/io5";
import { AiOutlineUser } from "react-icons/ai";
import { BiBook } from "react-icons/bi";
import { FaProjectDiagram } from "react-icons/fa";
import { CiMail } from "react-icons/ci";

import { useState } from "react";

const Nav = () => {
  const [activeNav, setActiveNav] = useState("#");

  function handleClick(navSelected) {
    setActiveNav(navSelected);
  }

  return (
    <nav>
      <a
        href="#home"
        className={activeNav === "#" ? "active" : ""}
        onClick={() => handleClick("#")}
      >
        <IoHomeOutline />
      </a>
      <a
        href="#about"
        className={activeNav === "#about" ? "active" : ""}
        onClick={() => handleClick("#about")}
      >
        <AiOutlineUser />
      </a>
      <a
        href="#experience"
        className={activeNav === "#experience" ? "active" : ""}
        onClick={() => handleClick("#experience")}
      >
        <BiBook />
      </a>
      <a
        href="#portfolio"
        className={activeNav === "#portfolio" ? "active" : ""}
        onClick={() => handleClick("#portfolio")}
      >
        <FaProjectDiagram />
      </a>
      <a
        href="#contact"
        className={activeNav === "#contact" ? "active" : ""}
        onClick={() => handleClick("#contact")}
      >
        <CiMail />
      </a>
    </nav>
  );
};

export default Nav;
