import P1 from "../../assets/Port_1.webp";
import P2 from "../../assets/Port_2.webp";
import P3 from "../../assets/Port_3.webp";
import P4 from "../../assets/Port_4.webp";
import P5 from "../../assets/Port_5.webp";
import P6 from "../../assets/Port_6.webp";

export const portfolio_data = [
  {
    image: P1,
    title: "Privacy Library Server",
    link: "https://github.com/avneetsingh36/library",
    link_questions: "#contact",
  },
  {
    image: P2,
    title: "Health Dashboard",
    link: "https://github.com/avneetsingh36/bloom",
    link_questions: "#contact",
  },
  {
    image: P3,
    title: "Password Manager",
    link: "https://github.com/avneetsingh36/password-manager",
    link_questions: "#contact",
  },
  {
    image: P4,
    title: "Mini Operating System",
    link: "https://github.com/avneetsingh36/task-master-os",
    link_questions: "#contact",
  },
  {
    image: P5,
    title: "Tabla Thihai Theory",
    link: "https://github.com/avneetsingh36/tabla-theory",
    link_questions: "#contact",
  },
  {
    image: P6,
    title: "Fuzzy Search",
    link: "https://github.com/avneetsingh36/fuzzy-search",
    link_questions: "#contact",
  },
];
