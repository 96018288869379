import "./experience.css";
import { FaHtml5 } from "react-icons/fa";
import { FaCss3Alt } from "react-icons/fa";
import { IoLogoJavascript } from "react-icons/io5";
import { FaReact } from "react-icons/fa";
import { TbBrandNextjs } from "react-icons/tb";
import { TbBrandSvelte } from "react-icons/tb";
import { FaVuejs } from "react-icons/fa";
import { SiTailwindcss } from "react-icons/si";

import { FaPython } from "react-icons/fa";
import { SiCplusplus } from "react-icons/si";
import { FaGolang } from "react-icons/fa6";
import { FaJava } from "react-icons/fa";
import { TbBrandPrisma } from "react-icons/tb";
import { FaRust } from "react-icons/fa";
import { FaNodeJs } from "react-icons/fa";
import { FaDocker } from "react-icons/fa";

export const frontendSkills = [
  {
    logo: <FaHtml5 className="experience__details-icon" />,
    skill: "HTML",
    proficiency: "Expert",
  },
  {
    logo: <FaCss3Alt className="experience__details-icon" />,
    skill: "CSS",
    proficiency: "Advanced",
  },
  {
    logo: <IoLogoJavascript className="experience__details-icon" />,
    skill: "JavaScript",
    proficiency: "Advanced",
  },
  {
    logo: <FaReact className="experience__details-icon" />,
    skill: "React",
    proficiency: "Expert",
  },
  {
    logo: <TbBrandNextjs className="experience__details-icon" />,
    skill: "Next.js",
    proficiency: "Expert",
  },
  {
    logo: <TbBrandSvelte className="experience__details-icon" />,
    skill: "Svelte",
    proficiency: "Advanced",
  },
  {
    logo: <FaVuejs className="experience__details-icon" />,
    skill: "Vue.js",
    proficiency: "Intermediate",
  },
  {
    logo: <SiTailwindcss className="experience__details-icon" />,
    skill: "Tailwind",
    proficiency: "Advanced",
  },
];

export const backendSkills = [
  {
    logo: <FaPython className="experience__details-icon" />,
    skill: "Python",
    proficiency: "Expert",
  },
  {
    logo: <SiCplusplus className="experience__details-icon" />,
    skill: "C/C++",
    proficiency: "Advanced",
  },
  {
    logo: <FaGolang className="experience__details-icon" />,
    skill: "Golang",
    proficiency: "Intermediate",
  },
  {
    logo: <FaJava className="experience__details-icon" />,
    skill: "Java",
    proficiency: "Advanced",
  },
  {
    logo: <TbBrandPrisma className="experience__details-icon" />,
    skill: "Prisma",
    proficiency: "Intermediate",
  },
  {
    logo: <FaRust className="experience__details-icon" />,
    skill: "Rust",
    proficiency: "Advanced",
  },
  {
    logo: <FaNodeJs className="experience__details-icon" />,
    skill: "Node.js",
    proficiency: "Intermediate",
  },
  {
    logo: <FaDocker className="experience__details-icon" />,
    skill: "Docker",
    proficiency: "Advanced",
  },
];
