import React from "react";
import "./contact.css";
import { AiOutlineMail } from "react-icons/ai";
import { FaCalendarAlt } from "react-icons/fa";
import { useRef } from "react";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_bko0398", "template_v6grrcy", form.current, {
        publicKey: "FpaFCibpbOJHVnq11",
      })
      .then(
        () => {
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );

    e.target.reset();
  };
  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>
      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <FaCalendarAlt className="contact__option-icon" />
            <h4>Calendly</h4>
            <h5>avneet-singh</h5>
            <a
              href="https://calendly.com/avneet-singh/30min"
              rel="noreferrer"
              target="_blank"
            >
              Schedule a Meeting
            </a>
          </article>
          <article className="contact__option">
            <AiOutlineMail className="contact__option-icon" />
            <h4>Email Me</h4>
            <h5>singhab2024@gmail.com</h5>
            <a href="mailto:singhab2024@gmail.com">Send a Message</a>
          </article>
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <input
            type="text"
            name="name"
            placeholder="Your Full Name"
            required
          />
          <input type="email" name="email" placeholder="Your Email" required />
          <textarea
            name="message"
            rows="6"
            placeholder="Your Message"
            required
          ></textarea>
          <button className="btn btn-primary">Send Message</button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
